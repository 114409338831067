import { EnvironmentInterface } from './environment-interface';
import { environment as baseEnvironment } from './base-environment';
import { DefaultPrivacyLevel } from '@datadog/browser-rum';

// Define an environment constant for QA that conforms to the EnvironmentInterface
// and extends the base environment constant with overrides for QA.
export const environment: EnvironmentInterface = {
    ...baseEnvironment,
    production: true,
    baseUrl: 'https://prod2-api.lockboxai.com',
    // baseUrl: 'http://localhost:8080',
    clientURL: 'https://prod2-app.lockboxai.com/home',

    versionCheckInterval: 30, // seconds

    datadogEnv: 'prod2',
    allowLocalLogin: true,
    flutterLoginURL: 'https://prod2-app.lockboxai.com/login',
    datadogDefaultPrivacyLevel: DefaultPrivacyLevel.ALLOW, // unmask all user input in QA env
    templateVersion: 'v1',
    wsUrl: 'wss://prod2-api.lockboxai.com/websocket',
    allowFlutterLogin: true
};
